<template>
    <div class="cases">
        <div class="container">
            <div class="row">
                <div class="col-md-8">
                    <div class="logo text-center">
                        <img style="width:100%; display:inline-block; text-align:center" src="../assets/donation.png" alt="logo">
                    </div>
                    <div class="cases-body text-right">
                        <Collapse simple>
                            <Panel name="1">
                                فصيلة الدم O-ve <span class="text-left">1-1-2020</span>
                                <p slot="content">
                                    حوجة عاجلة لمتبرعين بالدم فصيلة O-ve لعملية ولادة بمستشفى سعد أبو العلا تقاطع شارع
                                    محمد نجيب مع شارع 61
                                    نثق في سرعة استجابتكم للتبرع يرجى التواصل على الرقم 0999088040
                                </p>
                            </Panel>
                        </Collapse>
                        <Collapse simple>
                            <Panel name="1">
                                فصيلة الدم O-ve <span class="text-left">1-1-2020</span>
                                <p slot="content">
                                    حوجة عاجلة لمتبرعين بالدم فصيلة O-ve لعملية ولادة بمستشفى سعد أبو العلا تقاطع شارع
                                    محمد نجيب مع شارع 61
                                    نثق في سرعة استجابتكم للتبرع يرجى التواصل على الرقم 0999088040
                                </p>
                            </Panel>
                        </Collapse>
                        <Collapse simple>
                            <Panel name="1">
                                فصيلة الدم O-ve <span class="text-left">1-1-2020</span>
                                <p slot="content">
                                    حوجة عاجلة لمتبرعين بالدم فصيلة O-ve لعملية ولادة بمستشفى سعد أبو العلا تقاطع شارع
                                    محمد نجيب مع شارع 61
                                    نثق في سرعة استجابتكم للتبرع يرجى التواصل على الرقم 0999088040
                                </p>
                            </Panel>
                        </Collapse>
                        <Collapse simple>
                            <Panel name="1">
                                فصيلة الدم O-ve <span class="text-left">1-1-2020</span>
                                <p slot="content">
                                    حوجة عاجلة لمتبرعين بالدم فصيلة O-ve لعملية ولادة بمستشفى سعد أبو العلا تقاطع شارع
                                    محمد نجيب مع شارع 61
                                    نثق في سرعة استجابتكم للتبرع يرجى التواصل على الرقم 0999088040
                                </p>
                            </Panel>
                        </Collapse>
                        <Collapse simple>
                            <Panel name="1">
                                فصيلة الدم O-ve <span class="text-left">1-1-2020</span>
                                <p slot="content">
                                    حوجة عاجلة لمتبرعين بالدم فصيلة O-ve لعملية ولادة بمستشفى سعد أبو العلا تقاطع شارع
                                    محمد نجيب مع شارع 61
                                    نثق في سرعة استجابتكم للتبرع يرجى التواصل على الرقم 0999088040
                                </p>
                            </Panel>
                        </Collapse>
                        <Collapse simple>
                            <Panel name="1">
                                فصيلة الدم O-ve <span class="text-left">1-1-2020</span>
                                <p slot="content">
                                    حوجة عاجلة لمتبرعين بالدم فصيلة O-ve لعملية ولادة بمستشفى سعد أبو العلا تقاطع شارع
                                    محمد نجيب مع شارع 61
                                    نثق في سرعة استجابتكم للتبرع يرجى التواصل على الرقم 0999088040
                                </p>
                            </Panel>
                        </Collapse>
                    </div>
                </div>
                <div class="col-md-4">

                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: '',
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss">
    $secound_color : #F0F6F7FF;

    body {
        direction: rtl;
        background-color: $secound_color;

        .logo {
            padding: 5%;
        }

        .ivu-collapse {
            margin-bottom: 20px;

            span {
                display: inline-block;
                float: left;
                color: #666;
            }
        }
    }
</style>